@import '../../../styles/_variables.scss';

.Button__Container {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: .75rem 2rem;
  border-radius: 24px;
  border: none;
  @include button;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.drop-shadow {
    @include drop-shadow-500;
  }

  &.full-width {
    width: 100%;
  }

  & > * {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.primary {
  background-color: $primary-focus;
  transition: background-color 250ms ease;
  color: $primary-btn-text;

  &:hover {
    background-color: $primary-accent;
  }

  &.btn-pulse {
    @include pulse-animation(
      'btn-pulse-primary',
      background-color,
      $primary-main,
      $primary-focus,
      3000ms,
      ease,
    );

    &:hover {
      background-color: $secondary-focus;
      animation: unset;
    }
  }
}

.secondary {
  background-color: $secondary-main;
  transition: background-color 250ms ease;
  color: $white;

  &:hover {
    background-color: $secondary-focus;
  }

  &.btn-pulse {
    @include pulse-animation(
      'btn-pulse-secondary',
      background-color,
      $secondary-main,
      $secondary-focus,
      3000ms,
      ease,
    );

    &:hover {
      background-color: $secondary-focus;
      animation: unset;
    }
  }
}

.mixed {
  background-color: $secondary-disabled;
  transition: background-color 250ms ease,
    border 250ms ease,
    color 250ms ease;
  color: $secondary-main;
  border: 3px solid $secondary-disabled;

  &.selected {
    background-color: $primary-accent;

    border: 3px solid $secondary-main;

    color: $primary-btn-text;

    &:hover {
      background-color: $secondary-accent;
      border-color: $primary-accent;
    }
  }

  &:hover {
    border: 3px solid $secondary-accent;
    background-color: $secondary-accent;
  }

  &:disabled {
    border-color: $secondary-accent;
  }
}

.gradient {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding: .25rem;
  background: $gradient-primary;
  font-weight: 600;
  color: $primary-main;
  transition: color 250ms ease;
  position: relative;

  .Button__IconContainer,
  .Button__TextContainer {
    padding: .5rem 1.5rem;
    position: relative;
    z-index: 2;
  }

  .Button__BG {
    background-color: $white;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    transition: all 250ms ease;
    position: absolute;
    top: 0;
    left: 0;

    @include firefox {
      position: unset;
    }
  }

  &:hover {
    color: $white;

    .Button__BG {
      width: 0%;
      height: 0%;
      top: 50%;
      left: 50%;
      z-index: 1;
    }
  }
}

.Button__Container:disabled {
  background-color: $primary-disabled;
  cursor: default;
  color: $primary-btn-text;

  &:hover {
    background-color: $primary-disabled;
    border-color: $primary-disabled;
  }
}

.Button__IconContainer {
  height: $md;
  width: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  transform: translateX(-1rem);
  transition: width 250ms ease;

  &.b-icon-visible {
    width: $md;
  }
}

.Button__TextContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 250ms ease;

  &.b-icon-visible {
    transform: translateX(-.5rem);
  }
}
