.Modal__Container {
  left: 0;
  top: 50%;
  width: 100%;
  height: fit-content;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100004;
}

.Modal__Background {
  position: fixed;
  z-index: 100005;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
}

.Modal {
  width: fit-content;
  height: fit-content;
  z-index: 100006;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Modal__Close {
  cursor: pointer;
  align-self: flex-end;
}