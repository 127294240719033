@import '../../../styles/_variables.scss';

.VideoShowcaseStep__Page {
  @include education-page-spacing;
}

.VideoShowcaseStep__Container {
  @include education-content-spacing;
  justify-content: center;
  align-items: center;
}

.VideoShowcaseStep__HeaderContainer {
  margin-bottom: 1rem;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.VideoShowcaseStep__Header {
  @include mobile {
    text-align: center;
  }
}

.VideoShowcaseStep__MainContent {
  display: flex;
  flex-direction: column;
  width: 125vh;
  max-width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: -2.5%;

  @media screen and (max-height: $bp-md) {
    justify-content: flex-start;
    margin-top: unset;
  }

  @include mobile {
    justify-content: center;
    padding: unset;

    @media screen and (max-height: $bp-md) {
      justify-content: flex-start;
      margin-top: unset;
    }
  }
}

.VideoShowcaseStep__PlayerWrapper {
  position: relative;
  padding-top: 56.25%;
}

.VideoShowcaseStep__Player {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 250ms ease;
}

.VideoShowcaseStep__Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 65vh;

  @include pulse-animation(anim, background-color, $grey-100, $grey-200, 1.5s, ease);

  @include mobile {
    height: 50vw;
  }
}