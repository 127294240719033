@import '../../../styles/_variables.scss';

$radius: 8px;

.Checkbox__OuterContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0 4px;
}

.Checkbox__Img {
  @include safari {
    width: 100%;
    height: 100%;
  }
}

.Checkbox__InnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Checkbox__Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .25rem;
  width: 100%;
  position: relative;
  @include body-2;

  &.xss-center {
    justify-content: center;
  }
}

.Checkbox__Input {
  display: none;
}

.Checkbox__StateIndicator {
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1 / 1;
  border: 3px solid $secondary-main;
  border-radius: $radius;
  margin-right: .5rem;
  position: relative;
  background-color: $background;

  &.focus {
    border: 3px solid #2260c5;
  }

  &.small {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid $secondary-main;
  }
}

.Checkbox__StateIndicatorInner {
  width: 0%;
  height: 0%;
  background-color: $secondary-main;
  border: 0px solid $background;
  border-radius: $radius;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: width 250ms ease,
    height 250ms ease,
    top 250ms ease,
    left 250ms ease,
    border 250ms ease;

  &.checked {
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    border: 3px solid $background;
  }
}