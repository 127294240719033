@import '../../../styles/_variables.scss';

$drawer-open-width: 45%;
$drawer-closed-width: 0%;

@mixin drawer-open-fullscreen-bp {
  @media screen and (max-width: $bp-xl) {
    @content;
  }
}

.CarePathwaySelectionStep__Page {
  @include default-page-spacing;
  transition: padding 250ms ease;

  &.drawer-open {
    padding: unset;
  }
}

.CarePathwaySelectionStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
  transition: padding 250ms ease;

  &.drawer-open {
    padding: 0;
  }
}

.CarePathwaySelectionStep__Header {
  text-transform: capitalize;
  margin-bottom: 2rem;
  transition: padding 250ms ease;

  @include mobile {
    text-align: center;
  }

  &.drawer-open {
    padding: $default-padding;

    @include mobile {
      padding: 0 5%;
    }
  }
}

.CarePathwaySelectionStep__MainContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.CarePathwaySelectionStep__MainContentL {
  display: flex;
  flex-direction: column;
  width: calc(100% - $drawer-closed-width);
  height: 100%;
  opacity: 1;
  transition: width 250ms ease,
    height 250ms ease,
    opacity 250ms ease;

  &.drawer-open {
    width: calc(100% - $drawer-open-width);

    @include safari {
      @include tablet {
        width: 1%;
        opacity: 0;
        
        & > * {
          white-space: nowrap;
        }
      }
    }

    @include drawer-open-fullscreen-bp {
      width: 1%;
      height: 0%;
      opacity: 0;
      overflow: hidden;
      
      & > * {
        white-space: nowrap;
      }
    }
  }
}

.CarePathwaySelectionStep__MainContentL--Top {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid $secondary-main;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;

  @include mobile {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}

.CarePathwaySelectionStep__MainContentR {
  width: $drawer-closed-width;
  opacity: 0;
  background-color: $primary-main;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 250ms ease,
    opacity 250ms ease,
    padding 250ms ease;

  &.drawer-open {
    width: $drawer-open-width;
    opacity: 1;
    padding: 1rem;

    @include drawer-open-fullscreen-bp {
      width: 100%;
      height: fit-content;
      padding-bottom: 3rem;
    }

    @include safari {
      @include tablet {
        width: 100%;
        height: 90%;
      }

      @include mobile {
        height: fit-content;
      }
    }
  }
}

.CarePathwaySelectionStep__Outcomes {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;

  & > * {
    margin: 0 .25rem;
  }

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
}

.CarePathwaySelectionStep__IndicatorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  justify-content: flex-end;

  &.hip {
    min-width: 12rem;

    @include mobile {
      min-width: 100%;
    }
  }

  &.knee {
    min-width: 8rem;

    @include mobile {
      min-width: 100%;
    }
  }

  p {
    @include button;
    margin-bottom: 1rem;

    @include mobile {
      white-space: nowrap;
    }
  }

  @include mobile {
    margin-bottom: 2rem;
  }
}

.CarePathwaySelectionStep__DegIndicator {
  height: 9rem !important;
  width: 9rem !important;

  @include mobile {
    width: 50% !important;
    height: auto !important;
  }
}

.CarePathwaySelectionStep__PathwayOptionBtns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  white-space: nowrap;

  @media screen and (max-width: $bp-lg) {
    white-space: unset;
  }

  @include mobile {
    padding: 0;
    margin-bottom: 2rem;
  }
}

.CarePathwaySelectionStep__BtnsContainer {
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include mobile {
    justify-content: flex-start;
    padding-top: unset;
  }
}

.CarePathwaySelectionStep__PrimaryBtns,
.CarePathwaySelectionStep__SecondaryBtns {
  & > * {
    margin: .5rem 0;

    @include mobile {
      @include body-2;
      font-weight: 600;
    }
  }
}

.CarePathwaySelectionStep__PrimaryBtns {
  width: 100%;
  transition: width 250ms ease;
  padding: 0 2%;

  @include mobile {
    &.cpss-sb-open {
      width: 50%;
    }
  }
}

.CarePathwaySelectionStep__SecondaryBtns {
  width: 0%;
  opacity: 0;
  padding: 0;
  transition: width 250ms ease, opacity 250ms ease, padding 250ms ease;

  &.cpss-sb-open {
    opacity: 1;
    width: 100%;
    padding: 0 1rem;

    @include mobile {
      width: 50%;
    }
  }
}

.CarePathwaySelectionStep__PathwayResult {
  &.error {
    color: $error-main
  }

  &.success {
    color: $success-main
  }
}

.CarePathwaySelectionStep__DrawerToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  opacity: 1;
  transition: height 250ms ease,
    opacity 250ms ease;

  &.drawer-open {
    height: 0%;
    opacity: 0;
    overflow: hidden;
  }

  button span {
    white-space: nowrap;
    @include body-2;
    color: $primary-btn-text;
    padding-left: 1rem;
  }
}

.CarePathwaySelectionStep__DrawerToggleIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;

  & > * {
    stroke: $white;
  }

  path {
    fill: $white;
  }
}

.CarePathwaySelectionStep__CloseDrawerBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    @include header-3;
    font-weight: 500;
    color: $white;
    cursor: pointer;
    line-height: unset;
  }
}

.CarePathwaySelectionStep__DrawerHeader {
  color: $white;
  text-align: center;
  white-space: nowrap;
}

.CarePathwaySelectionStep__MatrixContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
}

$mc-lcol-width: 20%;

.CarePathwaySelectionStep__MatrixContainerL {
  width: $mc-lcol-width;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }

  @include safari {
    @include tablet {
      justify-content: flex-start;
    }
  }
}


.CarePathwaySelectionStep__MatrixContainerR {
  width: calc(100% - $mc-lcol-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }

  @include safari {
    @include tablet {
      justify-content: flex-start;
    }
  }

  .CarePathwaySelectionStep__MatrixLabel {
    margin-left: 30%;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }
}

.CarePathwaySelectionStep__MatrixLabel {
  @include body-1;
  color: $white;
  font-weight: 600;

  @include drawer-open-fullscreen-bp {
    @include body-2;
    color: $white;

    &.left {
      margin-top: 150%;
    }
  }
}
