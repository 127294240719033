@import '../../../styles/_variables.scss';

.NavigationFooter__Container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary-disabled;
  padding: 1rem 15%;
  -webkit-box-shadow: -1px 10px 40px 0px rgba(0, 0, 0, 0.30);
  -moz-box-shadow: -1px 10px 40px 0px rgba(0, 0, 0, 0.30);
  box-shadow: -1px 10px 40px 0px rgba(0, 0, 0, 0.30);
  position: relative;
  z-index: 100000;
  text-align: center;
  transition: padding 250ms ease;

  button {
    z-index: 2;
  }

  @include mobile {
    padding: 1rem 5%;

    button {
      font-size: $sm;
      font-weight: 600;
      width: 100%;
      white-space: nowrap;

      div {
        display: none;
      }
    }
  }
}

.NavigationFooter__ActionContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}

.NavigationFooter__Tag {
  @include body-2;
  font-weight: 600;
  padding: .25rem .5rem .25rem 1.25rem;
  background-color: $white;
  border-radius: 8px;
  border: 2px solid $secondary-main;
  z-index: 1;
  transform: translateX(-1rem);
  white-space: nowrap;
}