@import '../../../styles/_variables.scss';

.XrayEvaluationSubStep__Page {
  @include default-page-spacing;
}

.XrayEvaluationSubStep__EduPage {
  @include education-page-spacing;
}

.XrayEvaluationSubStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.XrayEvaluationSubStep__EduContainer {
  @include education-content-spacing;
  display: flex;
  flex-direction: column;
}

.XrayEvaluationSubStep__Header {
  margin-bottom: 2rem;

  &.xess-edu {
    margin-bottom: 0;
  }

  @include mobile {
    text-align: center;
  }
}

.XrayEvaluationSubStep__SubHeader {
  margin-bottom: 2rem;
  @include button;
}

.XrayEvaluationSubStep__MainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @include mobile {
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: flex-start;
    height: auto;
  }

  @include safari {
    @include tablet {
      flex-direction: column;
      flex-flow: column-reverse;
      justify-content: flex-start;
      height: auto;
    }
  }
}

.XrayEvaluationSubStep__MainContentL,
.XrayEvaluationSubStep__MainContentR {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }

  @include mobile {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
}

.XrayEvaluationSubStep__MainContentL {
  justify-content: flex-start;
  padding-top: 3%;

  @media screen and (max-height: $bp-sm) {
    padding-top: unset;
  }
}

.XrayEvaluationSubStep__AnswerOptsContainer {
  width: 100%;
  margin-bottom: 2rem;

  & > * {
    margin: .25rem 0;
  }
}

.XrayEvaluationSubStep__SubmitBtnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .correct {
    color: $success-main;
  }

  .incorrect {
    color: $error-main;
  }

  & > * {
    margin: 0 .5rem;
  }
}

.XrayEvaluationSubStep__XraysContainer {
  padding: 0 20%;
  & > * {
    margin: .5rem 0;
  }
}

.XrayEvaluationSubStep__XraysLabelContainer {
  position: relative;
}

.XrayEvaluationSubStep__XraysLabel {
  text-transform: capitalize;

  &.uppercase {
    text-transform: uppercase;
  }
}

.XrayEvaluationSubStep__ImageShowcaseFrame {
  height: 33vh;
  height: 33dvh;
  aspect-ratio: 1 / 1;

  &.xess-edu {
    height: 30vh;
    height: 30dvh;
  }
}

.XrayEvaluationSubStep__TutorialBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $secondary-main;
  cursor: pointer;
  font-size: 20px;
}