@import '../../../styles/_variables.scss';

.JointRecommendationStep__Page {
  @include default-page-spacing;
}

.JointRecommendationStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.JointRecommendationStep__Header {
  margin-bottom: 2rem;
  text-transform: capitalize;

  @include mobile {
    text-align: center;
  }
}

.JointRecommendationStep__MainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.JointRecommendationStep__CarePathway {
  font-weight: 500;
  color: $white;
  background-color: $secondary-main;
  padding: 2% 10%;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 2rem;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.JointRecommendationStep__Outcomes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid $secondary-main;
  padding-bottom: 2rem;

  & > * {
    margin: 0 2rem;
  }

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
    height: 100%;
  }
}

.JointRecommendationStep__Xrays {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // overflow: hidden;
  justify-content: center;
  padding: 3% 0;

  @include mobile {
    margin-bottom: 1rem;
    height: 100%;
    padding: 0;
    padding-bottom: 50vh;
  }
}

.JointRecommendationStep__XrayTile {
  height: 10rem !important;
  width: 10rem !important;
}

.JointRecommendationStep__IndicatorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  p {
    @include button;
    margin-bottom: 1rem;

    @include mobile {
      white-space: nowrap;
    }
  }

  @include mobile {
    margin-bottom: 1rem;
  }
}

.JointRecommendationStep__DegIndicator {
  height: 9rem !important;
  width: 9rem !important;
}