@import '../../../styles/_variables.scss';

.XrayMatchingTutorialModal__ContentContainer {
  width: 90vw;
  height: 90vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;

  @include mobile {
    padding: 0;
  }
}

.XrayMatchingTutorialModal__Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  &::-webkit-scrollbar{
    display: none;
  }

  &.xessm-center {
    justify-content: center;
  }
}

.XrayMatchingTutorialModal__Header {
  color: $white;
  width: 100%;

  @include mobile {
    @include header-3;
    color: $white;
    margin-bottom: 2rem;
  }
}

.XrayMatchingTutorialModal__SubHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  @include mobile {
    align-items: flex-start;
  }
}

.XrayMatchingTutorialModal__StepNumber {
  color: $white;
  margin-right: 2rem;
  font-size: $xxl;
}

.XrayMatchingTutorialModal__SubHeader {
  color: white;
  font-weight: 500;
  border-left: 1px solid $white;
  padding-left: 2rem;

  @include mobile {
    @include header-4;
    font-weight: 500;
    color: $white;
  }
}

.XrayMatchingTutorialModal__GifContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.XrayMatchingTutorialModal__Gif {
  height: 85%;
  width: 85%;
  object-fit: contain;

  @include mobile {
    height: 100%;
    width: 100%;
  }
}