@import '../../../styles/_variables.scss';

.OuterIconbutton__Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.OuterIconbutton__IconContainer {
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1 / 1;
  margin-right: 1rem;
  
  :first-child {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}