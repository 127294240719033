@import '../../../styles/_variables.scss';

.SurgeryMatrix__Container {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: .25rem;
  }
}

.SurgeryMatrix__Section {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 3px solid $background;

  &.sm-hilight {
    @include pulse-animation(
      "border",
      "border-color",
      $background,
      $secondary-accent,
      3s,
      ease,
    );
  }
}

.SurgeryMatrix__SectionL,
.SurgeryMatrix__SectionR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  min-height: 10rem;
}

.SurgeryMatrix__SectionL {
  width: 60%;
  text-align: left;

  :nth-child(2) {
    margin-top: 1rem;
  }

  p {
    color: $white;
    font-weight: 600;
  }
}

.SurgeryMatrix__SectionR {
  width: 40%;
  align-items: center;
  background-color: $background;
  text-align: center;

  &.sm-hilight-r {
    @include pulse-animation(
      "background",
      "background-color",
      $background,
      $secondary-accent,
      3s,
      ease,
    );
  }

  p {
    color: $secondary-main;
    font-weight: 600;
  }
}