@import './breakpoints.scss';

$upper-bar-height: 4rem;
$app-level-padding: $upper-bar-height;
$default-padding: 0 15%;
$education-panel-width: 20rem;

@mixin default-page-spacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
}

@mixin default-content-spacing {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: $default-padding;

  @include mobile {
    padding: 0 5%;
  }
}

@mixin education-page-spacing {
  @include default-page-spacing;
  width: calc(100% - $education-panel-width);
  transition: all 250ms ease;

  @include mobile {
    width: 0;
  }

  &.drawer-closed {
    width: 100%;
  }
}

@mixin education-content-spacing {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 2%;
  transition: all 250ms ease;

  &.drawer-closed {
    @include default-content-spacing();
  }
}