@import '../../../styles/_variables.scss';

.JointEvaluationStep__Page {
  @include default-page-spacing;
}

.JointEvaluationStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.JointEvaluationStep__Header {
  text-transform: capitalize;
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
  }
}

.JointEvaluationStep__MainContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.JointEvaluationStep__OutcomeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2rem;
}

.JointEvaluationStep__Label,
.JointEvaluationStep__Label--indicator {
  margin-bottom: 1rem;
}

.JointEvaluationStep__Label--indicator {
  text-transform: capitalize;
}

.JointEvaluationStep__DegIndicator {
  height: 60% !important;
}

.JointEvaluationStep__ActionsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.JointEvaluationStep__BtnsContainer {
  display: flex;
  flex-direction: column;
  padding: .5rem;
  width: 100%;

  & > * {
    margin: .25rem 0
  }
}

.JointEvaluationStep__DegIndicatorContainer {
  padding: .5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h4 {
    font-weight: 500;
  }

  .JointEvaluationStep__DegIndicator {
    height: 80% !important;
    border: 3px solid $secondary-main;

    @include safari {
      height: 13rem !important;
    }

    @include mobile {
      height: 12rem !important;
    }
  }
}

.JointEvaluationStep__ResultContainer {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    @include button;
    font-weight: 600;
    line-height: 0;
  }

  p.success {
    color: $success-main;
  }

  p.error {
    color: $error-main;
  }
}