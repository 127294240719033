@import '../../../styles/_variables.scss';

$panel-color: #eef2f6;
$handle-width: 2rem;

.EducationNavPanel__OuterContainer {
  width: fit-content;
  height: 100%;
  position: relative;
}

.EducationNavPanel__DrawerHandle {
  width: $handle-width;
  height: 5rem;
  background-color: $panel-color;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: calc(0rem - $handle-width);
  z-index: 10000;
  border: 2px solid $secondary-main;
  border-left: none;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: all 250ms ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary-main;
  @include drop-shadow-200;
  opacity: .75;

  svg {
    transition: all 250ms ease;
  }

  &:hover {
    opacity: 1;
    width: calc($handle-width + .25rem);
    right: calc(0rem - $handle-width - .25rem);

    svg {
      transform: translateX(.1rem);
    }
  }
}

.EducationNavPanel__Container {
  position: relative;
  width: $education-panel-width;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3.5rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 100001;
  transition: all 250ms ease;
  background: $panel-color;
  background: linear-gradient(0deg, $panel-color 87%, rgba(255,0,0,0) 100%);

  @include mobile {
    width: 100vw;
  }

  &.enp-closed {
    width: 0rem;
    overflow: hidden;
    padding: 3.5rem 0rem;
  }
}

.EducationNavPanel__CloseBtn {
  position: absolute;
  top: 1rem;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  color: $primary-main;
}

.EducationNavPanel__Header {
  color: $primary-main;
  text-align: center;
  border-bottom: 2px solid $primary-main;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.EducationNavPanel__NavItem {
  width: 100%;
  padding: 0 .5rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;

  &.enpni-disabled {
    cursor: default;
  }

  & > * {
    line-height: unset;
  }
}

.EducationNavPanel__NavItemBulletContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

$connector-transition-dur: 500ms;
$bullet-transtion-dur: calc($connector-transition-dur * 2);

.EducationNavPanel__NavItemBullet {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $primary-main;
  transition: all $bullet-transtion-dur ease;

  &.enpni-disabled {
    background-color: $primary-disabled;
  }
}

.EducationNavPanel__NavItemConnectorContainer {
  height: 2.5rem;
  width: 2px;
  overflow: hidden;
}

.EducationNavPanel__NavItemConnector {
  height: 100%;
  width: 100%;
  background-color: $primary-main;
  transition: all $connector-transition-dur ease;
}

.EducationNavPanel__NavItemLabel {
  font-weight: 500;
  transition: color $bullet-transtion-dur ease;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.enpni-active {
    color: $primary-main;
    font-weight: 600;
  }

  &.enpni-disabled {
    color: $primary-disabled;
  }
}
