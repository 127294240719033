@import '../../../styles/_variables.scss';

.TextButton__Container {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 24px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
  background: none;
  text-decoration: underline;

  p {
    @include button;
    color: $primary-main;
    text-decoration-color: $primary-main;
    transition: all 250ms ease;

    &:hover {
      color: $secondary-main;
      text-decoration-color: $secondary-main;
    }
  }
}