@import '../../../styles/_variables.scss';

.Surface__Container {
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: fit-content;
  
  &.contained {
    border: 3px solid $secondary-main
  }

  &.smooth {
    background-color: $secondary-disabled;
  }

  &.full-width {
    width: 100%;
  }
}