@import '../../../styles/variables';

.SummaryStep__Page {
  @include default-page-spacing;
}

.SummaryStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.SummaryStep__Header {
  margin-bottom: 2rem;
  text-transform: capitalize;
  text-align: center;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.SummaryStep__MainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }
}

.SummaryStep__MainContentL {
  width: 70%;
  height: 100%;

  @include mobile {
    width: 100%;
  }
}

.SummaryStep__MainContentR {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  svg {
    width: 100%;
    object-fit: contain;

    @include mobile {
      width: auto;
      height: 100%;
    }
  }

  @include mobile {
    justify-content: flex-start;
    width: 100%;
    height: 30%;
    margin-bottom: 2rem;
    display: none;
  }
}