@import '../../../styles/_variables.scss';

.PatientSurveySubStep__Page {
  @include default-page-spacing;
}

.PatientSurveySubStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: $bp-md) {
    align-items: flex-start;
  }
}

.PatientSurveySubStep__Survey,
.PatientSurveySubStep__Graphs {
  border: 3px solid transparent;

  &.psss-loaded {
    border: 3px solid $secondary-main;
  }
}

.PatientSurveySubStep__Survey {
  width: auto;
  height: 90%;

  @include mobile {
    width: 100%;
    height: auto;
  }
}

.PatientSurveySubStep__Graphs {
  width: 80%;
  height: auto;
  align-self: center;

  @include mobile {
    width: 100%;
    height: auto;
  }
}
