@import '../../../styles/_variables.scss';

.EmailFormGate__Container {
    padding: 10%;
}

.EmailFormGate__EmailFormContainer {
    grid-area: 1 / 1 / 2 / 2;
    padding: 1rem 15%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-2rem);
    @include drop-shadow-200;
    position: relative;
    z-index: 200000;

    @include mobile {
        padding: 1rem 10%;
    }

    &.home-visible {
        opacity: 1;
        transform: translateX(0);
    }
}

.EmailFormGate__FormLabel {
    @include body-1;
    text-align: center;

    @include mobile {
        font-weight: 600;
    }
}

.EmailFormGate__PersonaSelect,
.EmailFormGate__EmailInput,
.EmailFormGate__Checkbox {
    margin-bottom: .5rem;
}

.EmailFormGate__EmailForm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

.EmailFormGate__Checkbox {
    align-items: flex-start;

    @include mobile {
        align-items: center;
        text-align: left;
    }
}

.EmailFormGate__Buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}