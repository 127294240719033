@import '../../../styles/_variables.scss';

.SubtitleButton__Container {
  cursor: pointer;
  width: fit-content;
  padding: .75rem 2rem;
  border-radius: 24px;
  @include button;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &.full-width {
    width: 100%;
  }
}

.s-primary {
  background-color: transparent;
  transition: background-color 250ms ease,
    color 250ms ease,
    border-color 250ms ease;
  color: $secondary-main;
  border: 3px solid $primary-focus;

  &.s-fill-bg {
    background-color: $secondary-accent;
    // background-color: $primary-focus;

    color: $primary-btn-text;

    div p {
      color: $primary-btn-text;
    }

    &:hover {
      background-color: $primary-accent;
      border-color: $primary-accent;
    }
  }

  &.s-active {
    // background-color: $secondary-focus;
    background-color: $primary-focus;
    color: $white;

    &:hover {
      // background-color: $secondary-accent;
      background-color: $primary-accent;
    }
  }

  &:hover {
    background-color: $secondary-disabled;
  }

  .SubtitleButton__Subtitle {
    @include body-2;
    text-transform: capitalize;
    transition: color 250ms ease;

    &.s-active {
      color: $white;
    }
  }
}

.s-secondary {
  background-color: $secondary-main;
  transition: background-color 250ms ease;
  color: $white;

  &:hover {
    background-color: $secondary-focus;
  }
}

.SubtitleButton__Container:disabled {
  background-color: $secondary-disabled;
  border-color: $secondary-disabled;
  cursor: default;

  & > * {
    color: $secondary-accent;
    p {
      color: $secondary-accent
    }
  }

  &:hover {
    background-color: $secondary-disabled;
  }
}