@import '../../../styles/_variables.scss';

.PatientProfileStep__Page {
  @include default-page-spacing;
}

.PatientProfileStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.PatientProfileStep__Col {
  display: flex;
  flex-direction: column;
  width: 50%;

  @include mobile {
    width: 100%;
  }
}

.pps-rcol {
  align-items: center;
  justify-content: center;
}

.PatientProfileStep__MobileGraphic {
  display: none;

  @include mobile {
    display: block;
    height: fit-content;
  }
}

.PatientProfileStep__Graphic {
  padding: 5%;
  width: 100%;
}

.PatientProfileStep__ButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
}

.PatientProfileStep__HeaderContainer {
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.PatientProfileStep__Header {
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
  }
}

.PatientProfileStep__PatientBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding-right: 3rem;

  h4 {
    color: $secondary-main;
  }

  svg {
    margin-right: 1rem;
    height: 5rem;

    :nth-child(1) {
      fill: $secondary-main
    }
  }
}

.PatientProfileStep__PatientBadgeDetails {
  display: flex;
  flex-direction: column;
}

.PatientProfileStep__Label {
  span {
    font-weight: 600;
  }
}

.PatientProfileStep__Date {
  margin-bottom: 2rem;
}

.PatientProfileStep__MainContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 5vh;

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.PatientProfileStep__MainContentL,
.PatientProfileStep__MainContentR {
  width: 48%;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.PatientProfileStep__MainContentL>* {
  margin-bottom: 1.75rem;
}

.PatientProfileStep__MainContentR {
  flex-direction: row;
  align-items: center;

  svg {
    max-height: 20rem;
  }
}

.PatientProfileStep__ClipboardIcon {
  :nth-child(1) {
    fill: $secondary-main;
  }

  :nth-child(4) {
    stroke: $secondary-main;
  }

  path,
  circle {
    stroke: $secondary-main;
  }
}

.PatientProfileStep__UploadIcon {
  path {
    stroke: $secondary-main;
  }
}

.PatientProfileStep__XrayIcon {

  path,
  rect {
    stroke: $secondary-main;
  }

  ellipse,
  :nth-child(3),
  :nth-child(4),
  :nth-child(5),
  :nth-child(15),
  :nth-child(16) {
    fill: $secondary-main;
  }
}