@import '../../../styles/_variables.scss';

.DegenerationIndicator__Container {
  height: 100%;
  width: auto;
  aspect-ratio: 1 / 1;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;

  @include firefox {
    width: 10rem;
  }

  & > * {
    color: $primary-main;
    font-weight: 500;
  }

  &.error {
    background-color: $error-focus;
  }

  &.warning {
    background-color: $warning-focus;
  }

  &.success {
    background-color: $success-focus;
  }

  &.none {
    background-color: transparent;
  }
}
