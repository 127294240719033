@import '../../../styles/_variables.scss';

.Home__Page {
  width: 100%;
  height: 100%;
  padding: $default-padding;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  transform: translateY(calc(0rem - $upper-bar-height));
  position: relative;

  @include mobile {
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    transform: unset;
  }
}

.Home__Left {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    justify-content: flex-start;
    width: 100%;
  }

  @include tablet {
    justify-content: flex-start;
    padding-top: 10%;
  }

  @media screen and (max-height: 650px) {
    justify-content: flex-start;
  }
}

.Home__Logo {
  width: 65%;
  min-width: 20rem;
  min-height: 10rem;
  height: auto;
  margin-bottom: 1rem;
}

.Home__Header {
  @include header-2;
  color: $primary-main;
}

.Home__SubHeader {
  @include subheader-1;
  color: $primary-main;
  margin-bottom: 2rem;

  @include mobile {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.Home__Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  @include mobile {
    margin-bottom: 2rem;
  }
}

.Home__Buttons {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: opacity 250ms ease;
}

.Home__Buttons__Primary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3rem;

  @include mobile {
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: center;
  }
}

.Home__Button__Primary {
  width: 48% !important;

  @include mobile {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}

.Home__Buttons__Secondary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>* {
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 1rem;
    }
  }
}

.Home__Right {
  position: relative;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 100%;
    padding-bottom: 5vh;
    flex-direction: column-reverse;
  }
}

.Home__LegsImg {
  @include drop-shadow-200;
  height: 70%;

  @include mobile {
    height: 100%;

    @include safari {
      box-shadow: unset;
    }
  }
}

.accent {
  color: $secondary-main
}