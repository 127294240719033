@import '../../../styles/_variables.scss';

.App {
  width: 100%;
  height: 100vh;
  height: 100svh;
  background-color: $background;
  padding: $app-level-padding 0 0 0;
  position: relative;
  overflow-x: hidden;
}

.App__MenuContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  width: 100%;
  height: $upper-bar-height;
  padding: .5rem;
  z-index: 100003;
}

.App__Dropdown {
  height: 100%;
  transition: all 250ms ease;
  margin-left: 0;

  &.add-hidden {
    margin-left: -4.25rem;
  }
}

.App__Logo {
  width: 6rem;
  height: auto;
  margin-left: .5rem;
  transition: all 250ms ease;
  
  &.add-hidden {
    margin-left: 2rem;
  }
}