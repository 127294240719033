@import '../../../styles/_variables.scss';

$border-styles: 3px solid $secondary-main;

.ImageShowcaseFrame__Container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ImageShowcaseFrame__LabelContainer {
  width: 100%;
  padding: .25rem;
  background-color: $secondary-main;
  @include body-1;
  text-align: center;
  display: flex;
  justify-content: center;
}

.ImageShowcaseFrame__Label {
  color: $white;
  font-weight: 600;
}

.ImageShowcaseFrame__ImgContainer {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-left: $border-styles;
  border-right: $border-styles;
  border-bottom: $border-styles;
  background-color: $black;

  &.isf-transparent {
    background-color: transparent;
  }

  &.no-label {
    border-top: $border-styles;
  }
}

.ImageShowcaseFrame__Img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}