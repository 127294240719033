@import './breakpoints.scss';

@mixin safari {
  @supports (-webkit-hyphens:none) {
    @content
  }

  ;

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      @content;
    }
  }
}

@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin old-safari {
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      @content;
    }
  }
}

@mixin mobile {
  @media screen and (max-width: $bp-md) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $bp-xxl) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $bp-xxl) {
    @content;
  }
}