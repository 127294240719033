@import '../../../styles/_variables.scss';

.Input__Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.Input__InputContainer {
  width: 100%;
  position: relative;
}

.Input__Input {
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  background-color: $white;
  border: 2px solid $secondary-main;
  padding: 0 1rem 0 1rem;
  @include body-1;
  transition: padding 250ms ease;

  &.input--loading {
    padding: 0 2.5rem 0 1rem;
  }
}

.Input__LoadingIcon {
  width: 0rem;
  height: 100%;
  position: absolute;
  top: 35%;
  right: 1rem;
  transition: width 250ms ease;
  overflow: hidden;
  color: $primary-accent;
  z-index: 2;

  &.input--visible {
    width: 1rem;
  }
}

.Input__ErrorContainer {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 250ms ease;

  &.input--visible {
    grid-template-rows: 1fr;
  }
}

.Input__Error {
  @include body-2;
  color: $error-main;
  overflow: hidden;
}

label {
  @include body-2;
}