@import '../../../styles/_variables.scss';

.DynamicJointIndicator__Container {
  border-radius: 2rem;
  background-color: $secondary-focus;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 45vh;
  position: relative;

  &.hide-bg {
    background-color: transparent;
  }

  @include firefox {
    height: fit-content;
  }
}

.DynamicJointIndicator__SkeletonImg {
  display: block;
  height: 90%;
  width: auto;
  min-height: 8rem;
  z-index: 2;
  opacity: 0;
  transition: opacity 500ms ease;
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
  justify-self: center;

  @include safari {
    box-shadow: unset;
    filter: unset;
  }

  @include firefox {
    height: 60vh;
    align-self: flex-start;
  }

  &.dji-bg {
    @include drop-shadow-200;

    @include safari {
      box-shadow: unset;
      filter: unset;
    }
  }

  &.dji-visible {
    opacity: 1;
  }
}

.DynamicJointIndicator__JointIndicatorContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: auto;
  height: 15%;
  z-index: 1;
  top: 0;
  left: 0;

  transition: top 250ms ease,
    left 250ms ease,
    right 250ms ease,
    bottom 250ms ease;

  &.right-hip {
    top: 13%;
    left: 14%;

    @include old-safari {
      top: 15%;
      left: 15%;
    }
  }

  &.left-hip {
    top: 13%;
    left: 58%;

    @include old-safari {
      top: 15%;
      left: 55%;
    }
  }

  &.right-knee {
    top: 42%;
    left: 19%;
  }

  &.left-knee {
    top: 42%;
    left: 52%;
  }
}

.DynamicJointIndicator__JointIndicatorContainer__RJoint {
  position: absolute;
  display: flex;
  top: 10%;
  left: -11.4rem;
}

.DynamicJointIndicator__JointIndicator {
  background-color: $error-focus;
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}
