@import '../../../styles/_variables.scss';

.CareMatrix__Container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, fit-content);
}

.CareMatrix__Label,
.CareMatrix__Cell {
  display: flex;
  
  p {
    @include button;

    @include mobile {
      @include body-2;
    }
  }
}

.CareMatrix__Label {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.cell-2,
  &.cell-3 {
    text-align: center;
    justify-content: center;
  }

  &.cell-4,
  &.cell-7 {
    text-align: right;
    justify-content: flex-end;
  }

  p {
    color: $white;
  }
}

.CareMatrix__Cell {
  background-color: $background;
  border: .5rem solid $primary-main;
  // border: .5rem solid $secondary-main;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5%;
}

.cm-highlight {
  // background-color: $primary-accent;

  @include pulse-animation(
    "background",
    "background-color",
    $background,
    $secondary-accent,
    3s,
    ease,
  );
}