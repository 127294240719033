@import '../../../styles/_variables.scss';

.EducationCompleteStep__Page {
  @include education-page-spacing;
}

.EducationCompleteStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  @include safari {
    @include tablet {
      flex-direction: column;
    }
  }
}

.EducationCompleteStep__Logo {
  width: 90%;
  min-height: 7rem;
  min-width: 20rem;
  margin-bottom: 2rem;
}

.EducationCompleteStep__MainContentL,
.EducationCompleteStep__MainContentR {
  display: flex;
  flex-direction: column;
}

.EducationCompleteStep__MainContentL {
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }
}

.EducationCompleteStep__MainContentR {
  justify-content: center;
  align-items: center;

  @include mobile {
    height: 40vh;
  }

  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }
}

.EducationCompleteStep__Content {
  @include button;
  @include line-height($md);
  margin: .5rem 0;
}

.EducationCompleteStep__LegsImg {
  @include drop-shadow-200;
  height: 60vh;
  min-height: 25rem;

  @include safari {
    filter: unset;
    box-shadow: unset;
    
    @include tablet {
      flex-direction: column;
    }
  }
}