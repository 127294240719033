@import '../../../styles/_variables.scss';

.XrayTile__Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: .5rem;
  text-transform: capitalize;

  &.xt-pointer {
    cursor: pointer;
  }

  h4 {
    color: $secondary-main;
  }

  @include mobile {
    margin: 0;
  }

  @include safari {
    width: 40%;
    height: 40%;
  }
}

.XrayTile__XrayContainer {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: .5rem;
  overflow: hidden;
  background-color: $black;
  position: relative;
}

.XrayTile__Icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.538);
  transition: color 250ms ease;

  &:hover {
    color: rgba(255, 255, 255, 0.754);
  }
}

.XrayTile__Xray {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.XrayTile__LabelAbove {
  text-align: center;
  font-weight: 500;

  @include mobile {
    font-size: 1rem;
  }
}

.XrayTile__LabelBelow {
  text-align: center;
  font-weight: 500;
}

.XrayTile__XrayLarge {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  object-fit: contain;
}