@import '../../../styles/_variables.scss';

.IdentifyJointCompartmentsStep__Page {
  @include education-page-spacing;
}

.IdentifyJointCompartmentsStep__Container {
  @include education-content-spacing;
  display: flex;
  flex-direction: column;
}

.IdentifyJointCompartmentsStep__HeaderContainer {
  margin-bottom: 2rem;
  width: 100%;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.IdentifyJointCompartmentsStep__Header {
  @include mobile {
    text-align: center;
  }
}

.IdentifyJointCompartmentsStep__SubHeader {
  @include body-1;
  @include mobile {
    text-align: center;
  }
}

.IdentifyJointCompartmentsStep__MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.IdentifyJointCompartmentsStep__BtnContainer {
  width: 100%;
  padding: 0 3%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mobile {
    flex-direction: column;
    width: 100%;
  }
}

.IdentifyJointCompartmentsStep__OptButton {
  margin: 0 .5%;

  @include mobile {
    margin: .25rem 0;
  }
}

.IdentifyJointCompartmentsStep__ContentContainer {
  width: 100% !important;
  height: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;

  @include mobile {
    height: fit-content;
    padding-top: 2rem;
  }
}

.IdentifyJointCompartmentsStep__ImgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    height: fit-content;
  }
}

.IdentifyJointCompartmentsStep__GridStack {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  svg {
    width: 25vh;
    height: auto;
    grid-area: 1 / 1 / 2 / 2;
    opacity: 1;

    &.ijcs-hip {
      width: 45vh;
    }

    &.ijcs-hide {
      opacity: 0;
    }
  }

  @include mobile {
    height: fit-content;

    svg {
      width: 90%;
      height: 90%
    }
  }
}

.IdentifyJointCompartmentsStep__ImgLabel {
  @include body-1;
}

.IdentifyJointCompartmentsStep__IncorrectLabel {
  color: $error-focus;
}
