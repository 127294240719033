@import '../../../styles/_variables.scss';

$border-radius: 8px;

.DropdownSelect__SelectionContainer {
  border-radius: $border-radius;
  width: 100%;
  cursor: pointer;
  padding: .5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $secondary-main;
  position: relative;
  color: $secondary-main;
  background-color: $white;
}

.DropdownSelect__ChevronIcon {
  transition: transform 250ms ease;
}

.DropdownSelect__Selection {
  position: relative;
  z-index: 200;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  white-space: nowrap;
}

.DropdownSelect__OptionsContainer {
  transition: all 250ms ease;
  overflow: hidden;
  width: 100%;
  position: absolute;
  z-index: 20000000;
  left: 0;
  background-color: $white;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  background-color: $white;
  opacity: 1;
  
  &.dds-border--open {
    border: 1px solid $secondary-accent;
  }

  &.dds-border--closed {
    border: 1px solid transparent;
    opacity: 0;
  }

  &.dds-shadow {
    @include drop-shadow-200;
  }
}

.DropdownSelect__Option {
  width: 100%;
  transition: background-color 250ms ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: .5rem 1rem;
  overflow: hidden;

  &.dds-last {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:hover {
    background-color: $secondary-disabled;
  }
}