@import '../../../styles/_variables.scss';

.BulletItem__Container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.BulletItem__Bullet {
  margin-right: .5rem;
  margin-top: 6px;
  color: $primary-main;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}