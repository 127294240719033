@import '../../../styles/_variables.scss';

.EvaluationReportCardStep__Page {
  @include default-page-spacing;
}

.EvaluationReportCardStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.EvaluationReportCardStep__Header {
  text-transform: capitalize;
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
  }
}

.EvaluationReportCardStep__MainContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EvaluationReportCardStep__Table {
  align-self: flex-start;
  margin-bottom: 2rem;
}

.EvaluationReportCardStep__ScoreCard {
  padding: 2rem 3rem;
  height: 8rem;
  display: flex;
  justify-content: row;
  align-items: center;
}

.EvaluationReportCardStep__Score {
  color: $secondary-main;
}