@import '../../../styles/_variables.scss';

.JointSpaceNarrowingStep__Page {
  @include education-page-spacing;
}

.JointSpaceNarrowingStep__Container {
  @include education-content-spacing;
  display: flex;
  flex-direction: column;
}

.JointSpaceNarrowingStep__HeaderContainer {
  margin-bottom: 2rem;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.JointSpaceNarrowingStep__Header {
  text-transform: capitalize;

  @include mobile {
    text-align: center;
  }
}

.JointSpaceNarrowingStep__SubHeader {
  @include body-1;
  @include mobile {
    text-align: center;
  }
}

.JointSpaceNarrowingStep__MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.JointSpaceNarrowingStep__ContentContainer {
  width: 100% !important;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 8%;
  padding-right: 8%;

  @media screen and (max-height: $bp-md) {
    justify-content: flex-start;
    height: fit-content;
  }

  @include mobile {
    height: fit-content;
    padding: 1rem;
  }
}

.JointSpaceNarrowingStep__Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: .5rem 0;

  &.space-between {
    justify-content: space-between;
  }
}

.JointSpaceNarrowingStep__SelectionSquare {
  height: 20vh;
  width: 25%;
  margin: 0 .25rem;
  border-radius: 4px;
  background-color: $grey-100;
  overflow: hidden;
  position: relative;
  transition: transform 250ms ease;

  &.jsns-hover {
    &:hover {
      transform: translateY(-.25rem);
    }
  }

  &.with-img {
    background-color: $black;
  }
  
  &.jsns-pointer {
    cursor: pointer;
  }

  &.jsns-active {
    padding: 0 2%;
    background-color: $secondary-main;
    @include body-2;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;

    @include mobile {
      line-height: 12px;
      font-size: 10px;
      color: $white;
    }
  }

  @media screen and (max-width: $bp-lg) {
    height: auto;
    aspect-ratio: 1 / 1;

    &.jsns-active,
    &.with-img {
      height: 100%;
    }
  }
}

.JointSpaceNarrowingStep__IncorrectLabelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: .5rem;
  left: 0;
  opacity: 0;
  transition: opacity 250ms ease;

  &.jsns-visible {
    opacity: 1;
  }
}

.JointSpaceNarrowingStep__IncorrectLabel {
  color: $white;
  background-color: $error-main;
  font-weight: 600;
  border-radius: 4px;
  padding: .5rem 1rem;

  @include mobile {
    padding: .25rem;
  }
}

.JointSpaceNarrowingStep__XrayImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.JointSpaceNarrowingStep__ScaleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.JointSpaceNarrowingStep__Scale {
  width: 100%;
  height: 3rem;
  background-image: linear-gradient(to bottom left, $transparent 50%, $secondary-accent 50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top left;

  @include mobile {
    height: 1.5rem;
  }
}

.JointSpaceNarrowingStep__CompleteLabel {
  color: $success-main
}