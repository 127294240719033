@import '../../../styles/_variables.scss';

$bg-color: $grey-100;

.PDF__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 48rem;
  height: 100%;
}

.PDF__container__load {
  margin-top: 1em;
  color: white;
}

.PDF__container__document {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 3px solid $bg-color;
  border-radius: 1rem;
  background-color: $bg-color;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

.PDF__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDF__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.PDF__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
