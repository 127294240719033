@import '../../../styles/_variables.scss';

.XraySelectionStep__Page {
  @include education-page-spacing;
}

.XraySelectionStep__Container {
  @include education-content-spacing;
  display: flex;
  flex-direction: column;
}

.XraySelectionStep__HeaderContainer {
  margin-bottom: 2rem;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.XraySelectionStep__Header {
  text-transform: capitalize;

  @include mobile {
    text-align: center;
  }
}

.XraySelectionStep__SubHeader {
  opacity: 0;
  transform: translateX(-2rem);
  filter: blur(5px);

  &.xss-visible {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0px);
  }

  @include body-1;
  @include mobile {
    text-align: center;
  }
}

.XraySelectionStep__MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.XraySelectionStep__ContentContainer {
  width: 100% !important;
  min-height: fit-content;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.XraySelectionStep__SelectionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 22vh;
  min-width: 12rem;
  padding: .25rem;

  &.xss-hip {
    width: 20vw;
    padding: 6vh 0;
  }

  @include mobile {
    width: 100%;

    &.xss-hip {
      width: 100%;
      padding: unset;
    }
  }
}

.XraySelectionStep__CheckboxContainer {
  transition: border 250ms ease;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  &.rxss-hilight {
    border: 2px solid $success-main;
  }

  &.rxss-hilight-error {
    border: 2px solid $error-focus;
  }
}

.XraySelectionStep__CheckboxLabel {
  @include button;
  font-size: 14pt;
}

.XraySelectionStep__IconContainer {
  height: 0rem;
  width: 0rem;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0rem;
  transition: margin 250ms ease, width 250ms ease, height 250ms ease;

  &.rxss-with-icon {
    margin-right: .25rem;
    width: 2rem;
    height: 2rem;
  }
}

.XraySelectionStep__Icon {
  height: 0rem;
  width: 0rem;
  opacity: 0;
  object-fit: contain;
  transition: height 250ms ease, opacity 250ms ease;

  &.rxss-visible {
    height: 90%;
    width: 90%;
    opacity: 1;

    &.rxss-missed {
      height: 100%;
      width: 100%;
    }
  }

  &.rxss-success {
    path {
      stroke: $success-main;
    }
  }

  &.rxss-error {
    line {
      stroke: $error-focus;
    }
  }
}

.XraySelectionStep__XrayTile {
  height: 45%;

  @include safari {
    height: unset;
  }
}

.XraySelectionStep__SelectionResult {
  color: $error-focus;
}

.XraySelectionStep__XrayImg {
  @include safari {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;

    @include mobile {
      width: 100%;
      height: 100%;
    }
  }
}