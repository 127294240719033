@import '../../../styles/_variables.scss';

$col-width: 45%;

.PreliminaryReviewStep__Page {
  @include default-page-spacing;
}

.PreliminaryReviewStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  p {
    font-size: $md;
    @include line-height($md);
  }
}

.PreliminaryReviewStep__Left,
.PreliminaryReviewStep__Right {
  width: $col-width;

  @include mobile {
    width: 100%;
  }
}

.PreliminaryReviewStep__Right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @include mobile {
    margin-top: 2rem;
  }
}

.PreliminaryReviewStep__HeaderContainer {
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
  }
}

.PreliminaryReviewStep__ComplaintDetailsPatientName {
  @include body-1;
  font-size: $lg;
}

.PreliminaryReviewStep__ComplaintDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .PreliminaryReviewStep__SubHeader {
    font-weight: 600;
  }

  &>* {
    margin: .25rem 0;
  }
}

.PreliminaryReviewStep__PatientDemographics {
  display: flex;
  flex-direction: column;
  width: 100%;

  &>* {
    margin: .25rem 0;
  }
}

.PreliminaryReviewStep__DemographicsCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &>* {
    @include body-3;
    margin: .25rem 0;
  }
}

.PreliminaryReviewStep__DemographicsCard {
  width: 50%;
  text-transform: capitalize;

  span {
    font-weight: 600;
  }
}

.PreliminaryReviewStep__Label {
  text-decoration: underline;
  font-weight: 600;
}

.PreliminaryReviewStep__RightSubHeader {
  text-align: center;
  margin-bottom: 2rem;

  @include mobile {
    margin-bottom: 1rem;
  }
}