@import '../../../styles/_variables.scss';

.SBSTraining__Page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SBSTraining__Footer {
  padding: $default-padding;
  width: 100%;
  display: flex;
  justify-content: space-between;
}