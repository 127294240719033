@import './media-queries.scss';

@mixin drop-shadow-100 {
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.1));

  @include safari {
    filter: unset;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.1);
  }
}

@mixin drop-shadow-200 {
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.2));

  @include safari {
    filter: unset;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.2);
  }
}

@mixin drop-shadow-300 {
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.3));

  @include safari {
    filter: unset;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.3);
  }
}

@mixin drop-shadow-400 {
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.4));

  @include safari {
    filter: unset;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.4);
  }
}

@mixin drop-shadow-500 {
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.5));

  @include safari {
    filter: unset;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.5);
  }
}