@import '../../../styles/_variables.scss';

$border-radius: 1rem;

.DropdownMenu__Container {
  position: relative;
}

.DropdownMenu__IconContainer {
  padding: 1rem;
  cursor: pointer;
  border-radius: $border-radius;
  transition: all 250ms ease;
  color: $primary-main;

  &:hover {
    background-color: $secondary-disabled;
  }

  &.ddm-open {
    background-color: $primary-main;
    color: $white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    @include drop-shadow-200;
  }
}

.DropdownMenu__MenuContainer {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 250ms ease;
  display: grid;
  grid-template-rows: 0fr;
  transition: all 250ms ease;
  
  &.ddm-open {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}

.DropdownMenu__Button {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 250ms ease;

  &:hover {
    background-color: $secondary-main;
  }
  
  h4 {
    color: $white;
    font-weight: 500;
  }

  &.ddm-active h4 {
    font-weight: 600;
  }

  &.ddm-first {
    margin-top: 1rem;
  }

  &.ddm-last {
    margin-bottom: 1rem;
  }
}

.DropdownMenu__ButtonIconContainer {
  width: 2rem;
  height: 2rem;
  color: $white;
  margin-right: .5rem;

  &:first-child {
    height: 100%;
    object-fit: cover;
  }  
}

.DropdownMenu__Menu {
  width: fit-content;
  overflow: hidden;
  background-color: $primary-main;
  border-radius: $border-radius;
  border-top-left-radius: 0px;
  @include drop-shadow-200;
  min-width: 20rem;

  @include mobile {
    width: calc(100vw - 1rem);
  }
}