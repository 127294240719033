@import './themes.scss';

$active-theme: map-get($map: $themes, $key: "joint-ai-2");

$white: map-get($map: $active-theme, $key: "white");
$grey-100: map-get($map: $active-theme, $key: "grey-100");
$grey-200: map-get($map: $active-theme, $key: "grey-200");
$black: map-get($map: $active-theme, $key: "black");
$transparent: map-get($map: $active-theme, $key: "transparent");
$background: map-get($map: $active-theme, $key: "background");

$primary-main: map-get($map: $active-theme, $key: "primary-main");
$primary-focus: map-get($map: $active-theme, $key: "primary-focus");
$primary-accent: map-get($map: $active-theme, $key: "primary-accent");
$primary-disabled: map-get($map: $active-theme, $key: "primary-disabled");

$secondary-main: map-get($map: $active-theme, $key: "secondary-main");
$secondary-focus: map-get($map: $active-theme, $key: "secondary-focus");
$secondary-accent: map-get($map: $active-theme, $key: "secondary-accent");
$secondary-disabled: map-get($map: $active-theme, $key: "secondary-disabled");

$success-main: map-get($map: $active-theme, $key: "success-main");
$success-focus: map-get($map: $active-theme, $key: "success-focus");

$warning-main: map-get($map: $active-theme, $key: "warning-main");
$warning-focus: map-get($map: $active-theme, $key: "warning-focus");

$error-main: map-get($map: $active-theme, $key: "error-main");
$error-focus: map-get($map: $active-theme, $key: "error-focus");
$error-accent: map-get($map: $active-theme, $key: "error-accent");

$primary-btn-text: map-get($map: $active-theme, $key: "primary-btn-text");
$secondary-btn-text: map-get($map: $active-theme, $key: "secondary-btn-text");

$gradient-primary: linear-gradient(270deg, $secondary-main 0%, $primary-main) 100%;