@import '../../../styles/variables';

$main-color: $secondary-main;
$border: 3px solid $main-color;

$table-width: 100%;

$section-label-width: calc($table-width / 4);
$rc-section-label-width: calc($table-width / 3);

$row-label-container-width: $section-label-width;
$rc-row-label-container-width: $rc-section-label-width * 1.5;

$remaining-row-space: calc(100% - $row-label-container-width);
$rc-remaining-row-space: calc(100% - $rc-row-label-container-width);

$section-label-width-full: 58%; // TODO: refactor with math
$rc-section-label-width-full: calc($rc-section-label-width * 4);

$rows-container-width: calc(100% - $section-label-width);
$rc-rows-container-width: calc(100% - $rc-section-label-width);

$answer-marker-container-width: 5rem;
$section-height: 12vh;
$sect-height-mobile: $section-height;
$single-row-sect-height: calc($section-height / 2);
$column-label-height: 2rem;

h4, h5 {
  color: $secondary-main;
}

.XrayTable__Container {
  width: $table-width;
  display: flex;
  flex-direction: column;
  border: $border;
  text-transform: capitalize;
  text-align: center;
  margin-top: $column-label-height;

  &.dt-showMarkers {
    width: calc($table-width - $answer-marker-container-width);

    @include mobile {
      width: $table-width;
    }
  }
}

.XrayTable__SectionContainer {
  width: 100%;
  height: $section-height;
  min-height: 5rem;
  display: flex;
  flex-direction: row;

  &.dt-halfHeight {
    height: $single-row-sect-height;
    min-height: $single-row-sect-height;

    @include mobile {
      height: calc($sect-height-mobile / 2);
      min-height: calc($sect-height-mobile / 2);
    }
  }

  @include mobile {
    height: $sect-height-mobile;
    min-height: $sect-height-mobile;
  }
}

.XrayTable__SectionLabelContainer {
  width: $section-label-width;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: $border;
  border-bottom: $border;

  &.reportCard {
    width: $rc-section-label-width;

    &.dt-fullWidth {
      width: $rc-section-label-width-full;
    }
  }

  &.dt-last {
    border-bottom: unset;
  }

  &.dt-fullWidth {
    width: $section-label-width-full;
  }
}

.XrayTable__SectionLabel,
.XrayTable__RowLabel {
  overflow: hidden;
  width: 95%;
}

.XrayTable__SectionLabel {
  @include mobile {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;

    &.reportCard {
      @include body-2;
    }
  }
}

.XrayTable__RowLabel {
  font-weight: 500;
  line-height: 16px;

  @include mobile {
    padding: 0 2%;
    font-size: 12px;
    line-height: 14px;

    &.reportCard {
      @include body-2;
    }
  }
}

.XrayTable__RowsContainer {
  width: $rows-container-width;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.reportCard {
    width: $rc-rows-container-width;
  }
}

.XrayTable__Row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  position: relative;

  &.dt-halfHeight {
    height: 100%;
  }
}

.XrayTable__RowLabelContainer {
  width: $row-label-container-width;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: $border;
  border-bottom: $border;

  &.reportCard {
    width: $rc-row-label-container-width;
  }

  &.dt-last {
    border-bottom: unset;
  }
}

.XrayTable__Cell {
  width: calc($remaining-row-space / 4);
  height: 100%;
  border-right: $border;
  border-bottom: $border;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.reportCard {
    width: calc($rc-remaining-row-space / 2);
  }

  &.dt-end {
    border-right: unset;
  }

  &.dt-last {
    border-bottom: unset;
  }

  &.dt-fullWidth {
    width: 100%
  }

  &.success {
    background-color: $success-focus;
  }

  &.error {
    background-color: $error-focus;
  }

  &.warning {
    background-color: $warning-focus;
  }

  &.dt-hilight {
    background-color: $error-main;
    padding: 3px;
  }
}

.XrayTable__FirstSelectionLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #BFAD75;
  text-align: center;
  font-weight: 600;
  padding: 0 10%;

  @include mobile {
    line-height: unset;
    font-size: 10px;
    padding: unset;
  }
}

.XrayTable__CellLabel {
  width: 100%;
  height: $column-label-height;
  position: absolute;
  left: 0;
  top: calc(0rem - $column-label-height);
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include mobile {
    font-size: 10px;
    line-height: unset;
    top: -3rem;
  }
}

.XrayTable__CheckIcon {
  width: 1.5rem;
  height: 1.5rem;

  path {
    stroke: $main-color;
  }
}

.XrayTable__AnswerMarkerContainer {
  width: $answer-marker-container-width;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: calc(0% - $answer-marker-container-width);

  @include mobile {
    display: none;
  }
}

.XrayTable__AnswerMarkerCheck,
.XrayTable__AnswerMarkerX {
  width: 2rem;
  height: 2rem;
}

.XrayTable__AnswerMarkerCheck path {
  stroke: $success-focus;
}

.XrayTable__AnswerMarkerX line {
  stroke: $error-focus;
}