@import '../../../styles/_variables.scss';

$border-radius: 1rem;
$addtl-padding: .5rem;

.CornerTag__Container {
  background-color: $secondary-main;
  padding: 1rem 3rem;
  @include body-2;
  color: white;
  position: absolute;

  @include mobile {
    padding: $addtl-padding 1.5rem;
  }

  &.topRight {
    top: calc(0% - $app-level-padding);
    right: 0;
    border-bottom-left-radius: $border-radius;
  }

  &.topleft {
    top: calc(0% - $app-level-padding);
    left: 0;
    border-bottom-right-radius: $border-radius;
  }

  &.bottomRight {
    bottom: calc(0% - $app-level-padding);
    right: 0;
    border-top-left-radius: $border-radius;
  }

  &.bottomLeft {
    bottom: calc(0% - $app-level-padding);
    left: 0;
    border-top-right-radius: $border-radius;
  }
}