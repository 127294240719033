@import '../../../styles/_variables.scss';

.ReviewXraysSubStep__Page {
  @include default-page-spacing;
}

.ReviewXraysSubStep__Container {
  @include default-content-spacing;
}

.ReviewXraysSubStep__ReviewContainer {
  @include desktop {
    display: grid;
    justify-content: center;
    height: fit-content;
  }
}

.ReviewXraysSubStep__XrayContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  justify-content: center;
  align-items: center;
  padding: 0 10%;

  max-width: 60rem;

  @include mobile {
    grid-gap: .5rem;
    padding: 0 .5rem;
  }
}

.ReviewXraysSubStep__Header,
.ReviewXraysSubStep__SubHeader {
  @include mobile {
    text-align: center;
  }
}

.ReviewXraysSubStep__SubHeader {
  margin-bottom: 2rem;
  @include body-1;
}

.ReviewXraysSubStep__MainContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  gap: 2rem;

  @include mobile {
    flex-direction: column-reverse;
  }
}

.ReviewXraysSubStep__MainContentL {
  width: 48%;
  display: flex;
  flex-direction: column;

  &>* {
    margin: .1rem 0;
  }

  @include mobile {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.ReviewXraysSubStep__MainContentR {
  width: 48%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @include mobile {
    width: 100%;
  }
}

.ReviewXraysSubStep__SelectionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReviewXraysSubStep__CheckboxContainer {
  border: 3px solid $background;
  transition: border 250ms ease;
  padding: .5rem;
  border-radius: 4px;
  width: 100%;

  &.rxss-hilight {
    border: 3px solid $success-main;
  }

  &.rxss-hilight-error {
    border: 3px solid $error-focus;
  }
}

.ReviewXraysSubStep__CheckboxLabel {
  @include button;
}

.ReviewXraysSubStep__IconContainer {
  height: 100%;
  width: 0rem;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0rem;
  transition: margin 250ms ease, width 250ms ease;

  &.rxss-with-icon {
    margin-right: 1rem;
    width: 3rem;
  }
}

.ReviewXraysSubStep__Icon {
  height: 0rem;
  width: 0rem;
  opacity: 0;
  object-fit: contain;
  transition: height 250ms ease, opacity 250ms ease;

  &.rxss-visible {
    height: 90%;
    width: 90%;
    opacity: 1;

    &.rxss-missed {
      height: 100%;
      width: 100%;
    }
  }

  &.rxss-success {
    path {
      stroke: $success-main;
    }
  }

  &.rxss-error {
    line {
      stroke: $error-focus;
    }
  }
}

.ReviewXraysSubStep__XrayTile {
  width: 100%;
  height: 100%;
}

.ReviewXraysSubStep__SelectionResult {
  color: $error-focus;

  @include mobile {
    font-size: $sm;
    padding: .5rem;
  }
}