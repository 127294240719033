@import '../../../styles/_variables.scss';

.CTA__Page {
  width: 100%;
  height: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  @include mobile {
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
  }
}

.CTA__Content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10vh 5% 0 5%;

  &.left > * {
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  @include mobile {
    height: fit-content;
    padding: unset;
    align-items: center;
    margin-bottom: 3rem;
  }
}

.CTA__Logo {
  width: 100%;
  min-width: 90%;
  height: auto;
  min-height: 11rem;
  padding: 0 10%;
}

.CTA__Desc {
  @include body-1;

  @include mobile {
    font-size: $md;
    @include line-height($md);
    text-align: center;
  }
}

.CTA__BackBtn {
  width: 100% !important;
  text-align: center;
}

.CTA__FeaturePanel {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @include drop-shadow-200;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include mobile {
    height: fit-content;
    aspect-ratio: unset;
    padding: 5%;
    margin-bottom: 20vh
  }
}

.CTA__FeaturePanelCorner {
  border: 8px solid $primary-main;
  border-radius: 8px;
  height: 3rem;
  width: 3rem;

  &.top-left {
    grid-area: 1 / 1 / 2 / 2;
    border-right: unset;
    border-bottom: unset;
    align-self: flex-start;
  }

  &.top-right {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: flex-end;
    align-self: flex-start;
    border-left: unset;
    border-bottom: unset;
  }

  &.bottom-left {
    grid-area: 2 / 1 / 3 / 2;
    justify-self: flex-start;
    align-self: flex-end;
    border-right: unset;
    border-top: unset;
  }

  &.bottom-right {
    grid-area: 2 / 2 / 3 / 3;
    justify-self: flex-end;
    align-self: flex-end;
    border-left: unset;
    border-top: unset;
  }
}

.CTA__FeatureList {
  grid-area: 1 / 1 / 3 / 3;
  padding: 7% 8%;

  @include mobile {
    padding: 3% 5%;
  }

  & > * {
    margin: 1.5rem 0;
  }
}

.CTA__Bullet {
  @include body-1;
  color: $primary-main;

  @include mobile {
    font-size: $md;
    @include line-height($md);
    text-align: left;
  }
}