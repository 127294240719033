@import '../../../styles/_variables.scss';

.StepperController__Container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .5rem;

  & > * {
    margin: 0 .5rem;
  }
}

.StepperController__StepIndicator {
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 250ms ease;
  cursor: pointer;
  width: 5vw;
  height: 5vw;
  max-width: 2rem;
  max-height: 2rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;

  &.sc-active {
    background-color: $white;
  }
}