@import '../../../styles/_variables.scss';

.XrayConfirmationStep__Page {
  @include default-page-spacing;
}

.XrayConfirmationStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
}

.XrayConfirmationStep__Header {
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
  }
}

.XrayConfirmationStep__MainContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.XrayConfirmationStep__MainContentL,
.XrayConfirmationStep__MainContentR {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.XrayConfirmationStep__MainContentL {
  svg {
    width: 80%;
  }

  @include mobile {
    align-items: center;

    svg {
      width: 90%
    }
  }
}

.XrayConfirmationStep__MainContentR {
  @media screen and (max-height: $bp-sm) {
    justify-content: flex-start;
  }
}

.XrayConfirmationStep__ViewTypeSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid $secondary-main;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &.last {
    border-bottom: unset;
  }
}

.XrayConfirmationStep__ViewTypeSectionLabel {
  @include button;
  text-transform: capitalize;
  white-space: nowrap;
  width: 30%;
  font-weight: 600;
}

.XrayConfirmationStep__XrayButtons {
  width: 70%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: .25rem;
  }
}

.XrayConfirmationStep__Modal {
  width: 80vw;
  height: 80vh;
  height: 80dvh;
  color: $white;
}