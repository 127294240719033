@import './colors.scss', './media-queries.scss';

@font-face {
  font-family: "Sentico Sans";
  src: url('../assets/fonts/SenticoSansDT-Regular.ttf') format('truetype');
}

$main-font: "Sentico Sans", "Roboto", "Helvetica", "Arial", sans-serif;

$xsm: 8px;
$sm: 16px;
$md: 24px;
$lg: 32px;
$xl: 48px;
$xxl: 56px;

@mixin line-height($font-size) {
  line-height: calc($font-size * 1.5);
}

@mixin header-1 {
  font-family: $main-font;
  color: $primary-main;
  font-weight: 700;
  font-size: $xxl;
  @include line-height($xxl);
}

@mixin header-2 {
  font-family: $main-font;
  color: $primary-main;
  font-weight: 700;
  font-size: $xl;
  @include line-height($xl);
}

@mixin header-3 {
  font-family: $main-font;
  color: $primary-main;
  font-weight: 600;
  font-size: $lg;
  @include line-height($lg);

  @include mobile {
    @include line-height($md);
  }
}

@mixin header-4 {
  font-family: $main-font;
  color: $primary-main;
  font-weight: 600;
  font-size: $md;
  @include line-height($md);
}

@mixin header-5 {
  font-family: $main-font;
  color: $primary-main;
  font-weight: 600;
  font-size: $sm;
  @include line-height($sm);
}

@mixin subheader-1 {
  font-family: $main-font;
  color: $secondary-main;
  font-size: $lg;
  @include line-height($lg);
}

@mixin body-1 {
  font-family: $main-font;
  color: $secondary-main;
  font-size: $md;
  @include line-height($md);

  @include mobile {
    font-size: $sm;
    @include line-height($sm);
  }
}

@mixin body-2 {
  font-family: $main-font;
  color: $secondary-main;
  font-size: $sm;
  @include line-height($sm);
}

@mixin body-3 {
  font-family: $main-font;
  color: $secondary-main;
  font-size: $md;
  @include line-height($md);
}

@mixin button {
  font-family: $main-font;
  color: $secondary-main;
  font-weight: 500;
  font-size: $md;
}