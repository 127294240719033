@import '../../../styles/_variables.scss';

$main-content-width: 86%;
$side-label-sect-width: calc(100% - $main-content-width);

.OverallReportCardStep__Page {
  @include default-page-spacing;
}

.OverallReportCardStep__Container {
  @include default-content-spacing;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.OverallReportCardStep__Header {
  text-transform: capitalize;

  @include mobile {
    text-align: center;
    padding: 0 5%;
  }
}

.OverallReportCardStep__SubHeader {
  margin-bottom: 2rem;
  @include body-1;

  @include mobile {
    text-align: center;
  }
}

.OverallReportCardStep__MainContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    padding-bottom: 40vh;
  }
}

.OverallReportCardStep__OverviewContainer {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.OverallReportCardStep__OverviewContainerL {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  @include mobile {
    margin-bottom: 2rem;
  }

  h4 {
    font-weight: 500;
  }  
}

.OverallReportCardStep__ScoreCard {
  padding: 2rem 3rem;
  height: 8rem;
  display: flex;
  justify-content: row;
  align-items: center;
  align-self: flex-end;
}

.OverallReportCardStep__Score {
  color: $secondary-main;
}

.OverallReportCardStep__ReportCardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.OverallReportCardStep__ReportCardContainerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.OverallReportCardStep__ReportCardContainerRowLeftLabelSect {
  width: $side-label-sect-width;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2%;
  height: 90%;
  align-self: flex-end;

  &.hip {
    height: 88%;
  }

  @include mobile {
    display: none;
  }

  :first-child {
    padding-right: .5rem;
    text-align: right;
  }
}

.OverallReportCardStep__ReportCardContainerRowMainContentSect {
  width: $main-content-width;

  @include mobile {
    width: 100%;
  }
}

.OverallReportCardStep__ReportCardTablesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.OverallReportCardStep__XrayIndicator {
  align-self: flex-end;
  height: 100%;
  width: 10%;
  border: 2px solid $secondary-main;
  border-right: none;
}

.OverallReportCardStep__JointLabel {
  text-transform: uppercase;
}