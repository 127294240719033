@import '../../../styles/_variables.scss';

.SelectPatientStep__Page {
  @include default-page-spacing;
}

.SelectPatientStep__Container {
  @include default-content-spacing;
  overflow: clip;

  @include tablet {
    overflow: auto;
  }
}

.SelectPatientStep__HeaderContainer {
  padding: $default-padding;
  margin-bottom: 2rem;

  @include mobile {
    text-align: center;
    padding: 0 $app-level-padding;
  }
}

.SelectPatientStep__Header {
  @include header-3;
}

.SelectPatientStep__SubHeader {
  @include body-1;
}

.SelectPatientStep__MainContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  & > * {
    margin: 0 .5rem;
  }

  @include tablet {
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.SelectPatientStep__ButtonsLabel {
  width: 100%;
  position: absolute;
  left: 0;
  top: -.5rem;
  text-transform: capitalize;
  padding: 0 .5rem;
  white-space: nowrap;
  color: $secondary-main;
}

.SelectPatientStep__PatientButtons {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  padding: .5rem;
  padding-top: 2rem;
  position: relative;

  @include tablet {
    height: fit-content;
    width: 100%;
    margin-bottom: 2rem;
    overflow-y: unset;

    &.sps-knee {
      order: 1;
    }

    &.sps-hip {
      order: 2;
    }
  }

  & > * {
    margin: .25rem 0;
  }

  @include safari {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
}

.SelectPatientStep__DynamicJointIndicator {
  position: sticky;

  @include tablet {
    width: 20rem;
    order: 3;
    position: relative;
    align-self: center;
  }

  @include safari {
    align-self: center;
  }
}

.SelectPatientStep__CompleteTag {
  padding: 1% 5%;
  font-size: $xsm;
  color: $white;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  background-color: $success-main;

  p {
    color: $white;
  }
}

.SelectPatientStep__PatientButtonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include tablet {
    align-items: center;
  }
}

.SelectPatientStep__ScoreContainer {
  height: 6rem;
  width: 6rem;
  aspect-ratio: 1 / 1;
  margin-left: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  h4 {
    color: $secondary-main;
  }
}

.SelectPatientStep__ScoreLabel {
  width: 100%;
  position: absolute;
  left: 0;
  top: -1.5rem;
}

.SelectPatientStep__ScoreBreakdownBtn {
  cursor: pointer;
  color: $secondary-main;
  @include body-2;
  color: $primary-btn-text;
  font-size: 12px;
  text-align: center;
  line-height: unset;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: $primary-accent;
  transition: background-color 250ms ease;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-color: $primary-disabled;
  }
}